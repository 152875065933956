<template>
  <v-progress-linear
    v-if="isLoading"
    color="primary"
    indeterminate
  ></v-progress-linear>

  <v-container v-else class="py-12">
    <div class="d-flex align-center">
      <h1 class="text-h4">ENR</h1>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            outlined
            label
            :color="getStatusColor(instance)"
            v-bind="attrs"
            v-on="on"
            class="ml-4"
          >
            {{ instance.status }}
          </v-chip>
        </template>

        <span>
          Du {{ instance.start_date | formatDate }}
          au {{ instance.end_date | formatDate }}
        </span>
      </v-tooltip>
    </div>

    <h2
      class="text-subtitle-1"
      v-if="cursus"
    >
      {{ cursus }}
    </h2>

    <v-breadcrumbs
      :items="items"
      class="px-0 py-2"
    >
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <router-view
      :instanceId="instanceId"
      :instanceStatus="instance.status"
      :instanceType="instance.type"
      class="mt-6"
    ></router-view>
  </v-container>
</template>

<script>
import { getStatusColor } from '@/mixins/methodsMixin';

export default {
  name: 'AdminEnr',

  props: {
    instanceId: String,
  },

  mixins: [getStatusColor],

  data() {
    return {
      instance: null,
      cursus: null,
      isLoading: true,
      items: [
        {
          text: 'Instances',
          disabled: false,
          to: '/admin/instances',
        },
        {
          text: 'Cursus',
          disabled: false,
          to: `/admin/enr/${this.instanceId}`,
          exact: true,
        },
      ],
    };
  },

  async created() {
    await this.getInstance();
    if (Object.hasOwnProperty.call(this.$route.params, 'cursusId')) {
      this.getCursus(this.$route.params.cursusId);
      this.items.push({
        text: 'Inscriptions',
        disabled: true,
      });
    }
  },

  methods: {
    async getInstance() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.isLoading = true;
        const response = await this.$http.get(`admin/instance-get-by-id.php?instance_id=${this.instanceId}&type=ENR`);
        this.instance = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getCursus(id) {
      if (id === 'logo') this.cursus = 'Logopédie';
      else if (id === 'kine') this.cursus = 'Kinésithérapie';
      else this.$router.push('/admin/instances');
    },
  },

  watch: {
    $route(to) {
      if (Object.hasOwnProperty.call(to.params, 'cursusId')) {
        this.getCursus(to.params.cursusId);
        this.items.push({
          text: 'Inscriptions',
          disabled: true,
        });
      } else {
        this.cursus = null;
        this.items.pop();
      }
    },
  },
};
</script>
